import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const FooterActions: React.FC = ({ children }) => {
  return <Container>{children}</Container>;
};
