import { gql } from '@apollo/client';

export const EXHIBITIONS = gql`
  query Exhibitions {
    exhibitions {
      id
      slug
      imagePath
      svTitle
      enTitle
      svBody
      enBody
      svTimeDate
      enTimeDate
      published
      locationAnchorText
      locationAnchorLink
      exhibitionWebsiteAnchorText
      exhibitionWebsiteAnchorLink
      startAt
      endAt
    }
  }
`;
