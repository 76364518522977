import { gql } from '@apollo/client';

export const EXHIBITION_BY_SLUG = gql`
  query ExhibitionBySlug($slug: String!) {
    exhibition: exhibitionBySlug(slug: $slug) {
      id
      slug
      imagePath
      svTitle
      enTitle
      svBody
      enBody
      svTimeDate
      enTimeDate
      published
      locationAnchorText
      locationAnchorLink
      exhibitionWebsiteAnchorText
      exhibitionWebsiteAnchorLink
      startAt
      endAt
      youtubeVideoIds
      additionalExhibitionImages {
        id
        exhibitionID
        imagePath
        enDescription
        svDescription
        itemOrder
      }
    }
  }
`;
