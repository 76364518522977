import { gql } from '@apollo/client';

export const UPDATE_CONTACT = gql`
  mutation UpdateContact($input: ContactInput!) {
    updatedContact: updateContact(input: $input) {
      id
      email
      phone
      imagePath
      svText
      enText
      enHtmlTitle
      svHtmlTitle
      enHtmlDescription
      svHtmlDescription
    }
  }
`;
