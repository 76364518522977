import styled from 'styled-components';

export const ImageUploadContainer = styled.div`
  display: flex;
  flex-direction: column;

  img {
    margin-bottom: 15px;
  }
`;

export const MainPaintingImageHeading = styled.h3`
  margin-top: 5px;
`;

export const YoutubeVideoContainer = styled.div`
  display: inline-block;
  margin: 20px 5px 5px;
`;
