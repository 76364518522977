import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import PaletteIcon from '@material-ui/icons/Palette';
import WallpaperIcon from '@material-ui/icons/Wallpaper';
import AlternateEmail from '@material-ui/icons/AlternateEmail';
import Home from '@material-ui/icons/Home';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Button from '@material-ui/core/Button';
import { matchPath, useRouteMatch } from 'react-router-dom';

import { routes } from '@src/constants/routes';
import { logout } from '@src/utilities/logout';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: calc(100vh - 76px);
  width: 250px;
  padding: 30px 30px;
`;

const MainMenuItems = styled.div``;

interface MenuItemProps {
  isActive: boolean;
}

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding-left: 7px;
  border-left-style: solid;
  border-left-color: ${({ isActive }: MenuItemProps) =>
    isActive ? '#000' : 'transparent'};
  border-left-width: 3px;
  border-radius: 2px;
  transition: border-left-color 0.5s;
  &:hover {
    border-left-color: #000;
  }
  svg {
    font-size: 18px;
  }
  a {
    margin-left: 10px;
    padding: 5px 0;
    font-size: 16px;
    color: #000;
    text-decoration: none;
  }
`;

const mainMenuItems = [
  {
    name: 'Paintings',
    url: routes.paintings,
    icon: <PaletteIcon />,
  },
  {
    name: 'Exhibitions',
    url: routes.exhibitions,
    icon: <WallpaperIcon />,
  },
  {
    name: 'Home',
    url: routes.home,
    icon: <Home />,
  },
  {
    name: 'About',
    url: routes.about,
    icon: <AccountCircle />,
  },
  {
    name: 'Contact',
    url: routes.contact,
    icon: <AlternateEmail />,
  },
];

export const MainMenu = () => {
  const match = useRouteMatch();
  const handleLogout = () => {
    logout();
  };

  const renderMainItems = () =>
    mainMenuItems.map((item) => {
      const isActive = !!matchPath(item.url, match);
      return (
        <MenuItem key={item.url} isActive={isActive}>
          {item.icon}
          <Link to={item.url}>{item.name}</Link>
        </MenuItem>
      );
    });

  return (
    <Container>
      <MainMenuItems>{renderMainItems()}</MainMenuItems>
      <Button variant="outlined" onClick={handleLogout}>
        Log out
      </Button>
    </Container>
  );
};
