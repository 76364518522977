import React, { ReactElement } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';

import { FormType } from '@src/constants/forms';
import { PaintingID } from '@src/types/aliases';
import { PAINTING } from '@src/graphql/queries/painting';
import {
  CreateAdditionalPaintingImageInput,
  PaintingQuery,
  UpdateAdditionalPaintingImageInput,
  UpdatePaintingInput,
} from '@src/types/painting';
import { UPDATE_PAINTING } from '@src/graphql/mutations/updatePainting';
import { DELETE_PAINTING } from '@src/graphql/mutations/deletePainting';
import { routes } from '@src/constants/routes';

import { DashboardShell } from '@src/components/shared/DashboardShell/DashboardShell';
import { DashboardHeading } from '@src/components/shared/DashboardHeading/DashboardHeading';
import { PaintingForm } from '@src/components/Paintings/PaintingForm/PaintingForm';
import { Content } from '@src/components/shared/Content/Content';
import { client } from '@src/apollo-client';

export const PaintingUpdatePage = (): ReactElement | null => {
  const history = useHistory();
  const { paintingID: paintingIDUrl } = useParams<{ paintingID: string }>();
  const paintingID: PaintingID = Number(paintingIDUrl);
  const [updatePaintingMutation] = useMutation(UPDATE_PAINTING);
  const [deletePaintingMutation] = useMutation(DELETE_PAINTING);

  const { data } = useQuery<PaintingQuery>(PAINTING, {
    variables: { id: paintingID },
  });

  const handleUpdate = async (
    id: PaintingID,
    input: UpdatePaintingInput,
    additionalImagesToCreate: CreateAdditionalPaintingImageInput[],
    additionalImagesToUpdate: UpdateAdditionalPaintingImageInput[]
  ) => {
    await updatePaintingMutation({
      variables: {
        id,
        input,
        additionalImagesToCreate,
        additionalImagesToUpdate,
      },
    });
    await client.reFetchObservableQueries();
    history.push(routes.paintings);
  };

  const handleDelete = (id: PaintingID) => {
    deletePaintingMutation({
      variables: { id },
      update(cache) {
        cache.modify({
          fields: {
            paintings(existingPaintingRefs, { readField }) {
              return existingPaintingRefs.filter(
                (paintingRef: any) => id !== readField('id', paintingRef)
              );
            },
          },
        });
      },
    }).then(({ data: { success } }) => {
      if (success) {
        history.push(routes.paintings);
      }
    });
  };

  if (!data?.painting) {
    return null;
  }
  return (
    <DashboardShell>
      <DashboardHeading>Update Painting</DashboardHeading>
      <Content>
        <PaintingForm
          formType={FormType.Update}
          paintingToUpdate={data.painting}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
        />
      </Content>
    </DashboardShell>
  );
};
