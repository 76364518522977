import React, { useState, useEffect, ReactElement } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

import { toastAutoHideDuration } from '@src/constants/toasts';
import { HOME } from '@src/graphql/queries/home';
import { UPDATE_HOME } from '@src/graphql/mutations/updateHome';
import { HomeQuery, UpdateHomeInput } from '@src/types/home';

import { DashboardShell } from '@src/components/shared/DashboardShell/DashboardShell';
import { DashboardHeading } from '@src/components/shared/DashboardHeading/DashboardHeading';
import { FooterActions } from '@src/components/shared/FooterActions/FooterActions';
import { Content } from '@src/components/shared/Content/Content';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
  })
);

export const HomePage = (): ReactElement => {
  const classes = useStyles();
  const [enTitle, setEnTitle] = useState('');
  const [svTitle, setSvTitle] = useState('');
  const [enText, setEnText] = useState('');
  const [svText, setSvText] = useState('');
  const [enHtmlTitle, setEnHtmlTitle] = useState('');
  const [svHtmlTitle, setSvHtmlTitle] = useState('');
  const [enHtmlDescription, setEnHtmlDescription] = useState('');
  const [svHtmlDescription, setSvHtmlDescription] = useState('');
  const [isSavedToastOpen, setIsSavedToastOpen] = useState(false);
  const { data } = useQuery<HomeQuery>(HOME);
  const [updateHomeMutation] = useMutation(UPDATE_HOME);

  const setFetchedHomeData = () => {
    if (data?.home) {
      const home = data.home;
      setEnTitle(home.enTitle);
      setSvTitle(home.svTitle);
      setEnText(home.enText);
      setSvText(home.svText);
      setEnHtmlTitle(home.enHtmlTitle);
      setSvHtmlTitle(home.svHtmlTitle);
      setEnHtmlDescription(home.enHtmlDescription);
      setSvHtmlDescription(home.svHtmlDescription);
    }
  };
  useEffect(setFetchedHomeData, [data]);

  const handleEnHtmlTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    setEnHtmlTitle(target.value);
  };

  const handleSvHtmlTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    setSvHtmlTitle(target.value);
  };

  const handleEnHtmlDescriptionChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const target = e.target;
    setEnHtmlDescription(target.value);
  };

  const handleSvHtmlDescriptionChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const target = e.target;
    setSvHtmlDescription(target.value);
  };

  const handleEnTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    setEnTitle(target.value);
  };

  const handleSvTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    setSvTitle(target.value);
  };

  const handleEnTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    setEnText(target.value);
  };

  const handleSvTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    setSvText(target.value);
  };

  const handleToastClose = () => {
    setIsSavedToastOpen(false);
  };

  const handleSave = () => {
    const input: UpdateHomeInput = {
      enTitle,
      svTitle,
      enText,
      svText,
      enHtmlTitle,
      svHtmlTitle,
      enHtmlDescription,
      svHtmlDescription,
    };
    updateHomeMutation({
      variables: { input },
    }).then(() => {
      setIsSavedToastOpen(true);
    });
  };

  return (
    <DashboardShell>
      <DashboardHeading>Home</DashboardHeading>
      <Content>
        <Grid container spacing={3}>
          <Grid item md={6} className={classes.root}>
            <TextField
              data-testid="home-en-html-title-field"
              value={enHtmlTitle}
              fullWidth
              label="HTML Title 🇬🇧"
              rows={10}
              variant="outlined"
              onChange={handleEnHtmlTitleChange}
            />
          </Grid>
          <Grid item md={6} className={classes.root}>
            <TextField
              data-testid="home-sv-html-title-field"
              value={svHtmlTitle}
              fullWidth
              label="HTML Title 🇸🇪"
              rows={10}
              variant="outlined"
              onChange={handleSvHtmlTitleChange}
            />
          </Grid>
          <Grid item md={6} className={classes.root}>
            <TextField
              data-testid="home-en-html-description-field"
              value={enHtmlDescription}
              fullWidth
              label="HTML Description 🇬🇧"
              multiline
              rows={10}
              variant="outlined"
              onChange={handleEnHtmlDescriptionChange}
            />
          </Grid>
          <Grid item md={6} className={classes.root}>
            <TextField
              data-testid="home-sv-html-description-field"
              value={svHtmlDescription}
              fullWidth
              label="HTML Description 🇸🇪"
              multiline
              rows={10}
              variant="outlined"
              onChange={handleSvHtmlDescriptionChange}
            />
          </Grid>
          <Grid item md={6} className={classes.root}>
            <TextField
              data-testid="home-title-en-field"
              value={enTitle}
              fullWidth
              label="Title 🇬🇧"
              rows={10}
              variant="outlined"
              onChange={handleEnTitleChange}
            />
          </Grid>
          <Grid item md={6} className={classes.root}>
            <TextField
              data-testid="home-title-sv-field"
              value={svTitle}
              fullWidth
              label="Title 🇸🇪"
              rows={10}
              variant="outlined"
              onChange={handleSvTitleChange}
            />
          </Grid>
          <Grid item md={6} className={classes.root}>
            <TextField
              data-testid="home-text-en-field"
              value={enText}
              fullWidth
              label="Body text 🇬🇧"
              multiline
              rows={10}
              variant="outlined"
              onChange={handleEnTextChange}
            />
          </Grid>
          <Grid item md={6} className={classes.root}>
            <TextField
              data-testid="home-text-sv-field"
              value={svText}
              fullWidth
              label="Body text 🇸🇪"
              multiline
              rows={10}
              variant="outlined"
              onChange={handleSvTextChange}
            />
          </Grid>
        </Grid>
        <FooterActions>
          <Button
            data-testid="save-button"
            variant="contained"
            color="primary"
            onClick={handleSave}
          >
            Save
          </Button>
        </FooterActions>
        <Snackbar
          open={isSavedToastOpen}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={toastAutoHideDuration}
          onClose={handleToastClose}
        >
          <Alert variant="filled" severity="success">
            Saved successfully &nbsp;❤️
          </Alert>
        </Snackbar>
      </Content>
    </DashboardShell>
  );
};
