export const routes = {
  root: '/',
  paintings: '/paintings',
  paintingsCreate: '/paintings/create',
  paintingsUpdate: '/paintings/:paintingID/update',
  exhibitions: '/exhibitions',
  exhibitionsCreate: '/exhibitions/create',
  exhibitionsUpdate: '/exhibitions/:slug/update',
  contact: '/contact',
  about: '/about',
  home: '/home',
};
