import React from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import AddPhotoAlternate from '@material-ui/icons/AddPhotoAlternate';

import {
  AdditionalImageType,
  CreateAdditionalImageInput,
} from '@src/types/image';

import * as S from './AdditionalImages.S';
import { AdditionalImage } from '../AdditionalImage/AdditionalImage';
import { IconButton } from '@material-ui/core';

export const AdditionalImages: React.FC<{
  additionalImages: (AdditionalImageType | CreateAdditionalImageInput)[];
  onRemove: (id: number) => void;
  onChange: (
    additionalImage: AdditionalImageType | CreateAdditionalImageInput,
    index: number
  ) => void;
  onChangeAll: (
    additionalImages: (AdditionalImageType | CreateAdditionalImageInput)[]
  ) => void;
}> = ({ additionalImages, onChange, onChangeAll, onRemove }) => {
  const handleDragEnd = ({ source, destination }: DropResult) => {
    if (!destination) {
      return;
    }
    if (source.index === destination.index) {
      return;
    }
    let newAdditionalImages = [...additionalImages];
    const draggedAdditionalImages = newAdditionalImages.splice(source.index, 1);
    newAdditionalImages.splice(
      destination.index,
      0,
      ...draggedAdditionalImages
    );
    newAdditionalImages = newAdditionalImages.map((additionalImage, index) => ({
      ...additionalImage,
      itemOrder: index,
    }));
    onChangeAll(newAdditionalImages);
  };

  const hasNullImage = additionalImages.some(
    (additionalImage) =>
      'fileUpload' in additionalImage && additionalImage.fileUpload === null
  );

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="1">
        {(provided) => (
          <S.Container {...provided.droppableProps} ref={provided.innerRef}>
            <h3>Images:</h3>
            {additionalImages.map((additionalImage, i) => (
              <Draggable
                key={
                  'id' in additionalImage
                    ? additionalImage.id
                    : additionalImage.fileUpload?.name || 5000
                }
                draggableId={String(
                  'id' in additionalImage
                    ? additionalImage.id
                    : additionalImage.fileUpload?.name || 5000
                )}
                index={i}
              >
                {(providedDraggable) => (
                  <AdditionalImage
                    {...providedDraggable.draggableProps}
                    {...providedDraggable.dragHandleProps}
                    ref={providedDraggable.innerRef}
                    additionalImage={additionalImage}
                    onChange={(newAdditionalImage) =>
                      onChange(newAdditionalImage, i)
                    }
                    onRemove={() => {
                      if ('id' in additionalImage) {
                        const shouldDelete = window.confirm('Are you sure?');
                        if (shouldDelete) {
                          onRemove(additionalImage.id);
                        } else {
                          return;
                        }
                      }
                      onChangeAll(
                        additionalImages
                          .filter((_, index) => index !== i)
                          .map((additionalImage, i) => ({
                            ...additionalImage,
                            itemOrder: i,
                          }))
                      );
                    }}
                  />
                )}
              </Draggable>
            ))}
            <IconButton
              color="primary"
              disabled={hasNullImage}
              style={{ marginTop: '10px' }}
              onClick={() => {
                onChangeAll([
                  ...additionalImages,
                  {
                    fileUpload: null,
                    enDescription: '',
                    svDescription: '',
                    itemOrder: additionalImages.length,
                  },
                ]);
              }}
            >
              <AddPhotoAlternate fontSize="small" />
            </IconButton>
          </S.Container>
        )}
      </Droppable>
    </DragDropContext>
  );
};
