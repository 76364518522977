import { gql } from '@apollo/client';

export const UPDATE_ABOUT = gql`
  mutation UpdateAbout($input: AboutInput!) {
    updatedAbout: updateAbout(input: $input) {
      id
      imagePath
      enText
      svText
      enHtmlTitle
      svHtmlTitle
      enHtmlDescription
      svHtmlDescription
    }
  }
`;
