import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';

import { FormType } from '@src/constants/forms';
import {
  CreateAdditionalExhibitionImageInput,
  ExhibitionBySlugQuery,
  UpdateAdditionalExhibitionImageInput,
  UpdateExhibitionInput,
} from '@src/types/exhibition';
import { UPDATE_EXHIBITION } from '@src/graphql/mutations/updateExhibition';
import { DELETE_EXHIBITION } from '@src/graphql/mutations/deleteExhibition';
import { routes } from '@src/constants/routes';

import { DashboardShell } from '@src/components/shared/DashboardShell/DashboardShell';
import { DashboardHeading } from '@src/components/shared/DashboardHeading/DashboardHeading';
import { ExhibitionForm } from '@src/components/Exhibitions/ExhibitionForm/ExhibitionForm';
import { Content } from '@src/components/shared/Content/Content';
import { URLSlug } from '@src/types/aliases';
import { EXHIBITION_BY_SLUG } from '@src/graphql/queries/exhibitionBySlug';
import { client } from '@src/apollo-client';

export const ExhibitionUpdatePage: React.FC = () => {
  const history = useHistory();
  const { slug } = useParams<{ slug: URLSlug }>();
  const [updateExhibitionMutation] = useMutation(UPDATE_EXHIBITION);
  const [deleteExhibitionMutation] = useMutation(DELETE_EXHIBITION);

  const { data } = useQuery<ExhibitionBySlugQuery>(EXHIBITION_BY_SLUG, {
    variables: { slug },
  });

  const handleUpdate = async (
    slug: URLSlug,
    input: UpdateExhibitionInput,
    additionalImagesToCreate: CreateAdditionalExhibitionImageInput[],
    additionalImagesToUpdate: UpdateAdditionalExhibitionImageInput[]
  ) => {
    await updateExhibitionMutation({
      variables: {
        slug,
        input,
        additionalImagesToCreate,
        additionalImagesToUpdate,
      },
    });
    await client.reFetchObservableQueries();
    history.push(routes.exhibitions);
  };

  const handleDelete = (slug: URLSlug) => {
    deleteExhibitionMutation({
      variables: { slug },
      update(cache) {
        cache.modify({
          fields: {
            exhibitions(existingExhibitionRefs, { readField }) {
              return existingExhibitionRefs.filter(
                (exhibitionRef: any) =>
                  slug !== readField('slug', exhibitionRef)
              );
            },
          },
        });
      },
    }).then(({ data: { success } }) => {
      if (success) {
        history.push(routes.paintings);
      }
    });
  };

  if (!data?.exhibition) {
    return null;
  }

  return (
    <DashboardShell>
      <DashboardHeading>Update Exhibition</DashboardHeading>
      <Content>
        <ExhibitionForm
          formType={FormType.Update}
          exhibitionToUpdate={data.exhibition}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
        />
      </Content>
    </DashboardShell>
  );
};
