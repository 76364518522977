import React, { ReactElement, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { Button, Typography } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

import { PAINTINGS } from '@src/graphql/queries/paintings';
import { REORDER_PAINTINGS } from '@src/graphql/mutations/reorderPaintings';
import { routes } from '@src/constants/routes';
import { toastAutoHideDuration } from '@src/constants/toasts';
import {
  Painting,
  PaintingsQuery,
  ReorderPaintingInput,
} from '@src/types/painting';

import { DashboardShell } from '@src/components/shared/DashboardShell/DashboardShell';
import { DashboardHeading } from '@src/components/shared/DashboardHeading/DashboardHeading';
import { PaintingsTable } from '../../Paintings/PaintingsTable/PaintingsTable';
import { Content } from '@src/components/shared/Content/Content';
import { FadeInMessage } from '@src/components/shared/styles/FadeInMessage.S';

export const PaintingsPage = (): ReactElement | null => {
  const { data, loading } = useQuery<PaintingsQuery>(PAINTINGS);
  const [paintings, setPaintings] = useState<Painting[]>([]);
  const [reorderPaintingsMutation] = useMutation(REORDER_PAINTINGS);
  const [isSavedToastOpen, setIsSavedToastOpen] = useState(false);

  useEffect(() => {
    if (data?.paintings) {
      const newPaintings = [...data.paintings];
      newPaintings.sort(
        (a: Painting, b: Painting) => a.itemOrder - b.itemOrder
      );
      setPaintings(newPaintings);
    }
  }, [data]);

  const handleReorderClick = (): void => {
    const reorders: ReorderPaintingInput[] = paintings.map(
      ({ id, itemOrder }: Painting) => ({
        paintingID: id,
        itemOrder,
      })
    );
    reorderPaintingsMutation({
      variables: { reorders },
    }).then(() => {
      setIsSavedToastOpen(true);
    });
  };

  const hasPaintings = (): boolean => paintings.length > 0;

  const renderHeaderActions = (): ReactElement => (
    <>
      {hasPaintings() && (
        <Button
          variant="contained"
          color="default"
          style={{ marginRight: '15px' }}
          onClick={handleReorderClick}
        >
          Save reorder
        </Button>
      )}
      <Link to={routes.paintingsCreate}>
        <Button variant="contained" color="primary">
          Create a Painting
        </Button>
      </Link>
    </>
  );

  const handleToastClose = () => {
    setIsSavedToastOpen(false);
  };

  if (loading) {
    return null;
  }

  return (
    <DashboardShell>
      <DashboardHeading actions={renderHeaderActions()}>
        Paintings
      </DashboardHeading>
      <Content>
        {hasPaintings() ? (
          <PaintingsTable
            paintings={paintings}
            onPaintingsChange={setPaintings}
          />
        ) : (
          <FadeInMessage>
            <Typography variant="body2" component="p">
              You don't have any paintings, add one by clicking on "Create a
              Painting"! 🎨😍
            </Typography>
          </FadeInMessage>
        )}
        <Snackbar
          open={isSavedToastOpen}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={toastAutoHideDuration}
          onClose={handleToastClose}
        >
          <Alert variant="filled" severity="success">
            Saved successfully &nbsp;❤️
          </Alert>
        </Snackbar>
      </Content>
    </DashboardShell>
  );
};
