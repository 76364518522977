import styled from 'styled-components';

export const TextsContainer = styled.div`
  margin-bottom: 30px;
`;

export const ImageUploadContainer = styled.div`
  margin: 15px 0;
  display: flex;
  flex-direction: column;

  img {
    margin-bottom: 15px;
  }
`;
