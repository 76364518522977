import React from 'react';
import styled from 'styled-components';

import Badge from '@material-ui/core/Badge';

import { MainMenu } from '../MainMenu/MainMenu';

import logo from '@src/images/logo.svg';

const Container = styled.div`
  display: flex;
`;
const MenuColumn = styled.div`
  width: 250px;
  background-color: #fafafa;
`;

const ContentColumn = styled.div`
  padding: 20px 35px;
  flex-grow: 1;
`;

const LogoContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 20px;
`;

const BadgeContainer = styled.div`
  position: absolute;
  top: 8px;
  right: 70px;
`;

export const DashboardShell = ({ children }: any) => {
  return (
    <Container>
      <MenuColumn>
        <LogoContainer>
          <img src={logo} alt="LenaLotten Logotyp" />
          <BadgeContainer>
            <Badge badgeContent="Admin" color="primary" />
          </BadgeContainer>
        </LogoContainer>
        <MainMenu />
      </MenuColumn>
      <ContentColumn>{children}</ContentColumn>
    </Container>
  );
};
