import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

import { toastAutoHideDuration } from '@src/constants/toasts';
import logo from '@src/images/logo.svg';
import { SIGN_IN } from '@src/graphql/mutations/signIn';
import { routes } from '@src/constants/routes';
import { storageToken } from '@src/constants/keys';

const Page = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SignInContainer = styled.div`
  width: 340px;
  max-width: 90%;
`;

const SignInCard = styled.div`
  margin-top: 30px;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
`;

const TitleContainer = styled.div`
  margin-bottom: 15px;
  text-align: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;

const PasswordInputContainer = styled.div`
  margin-top: 15px;
`;

export const SignInPage: React.FC<RouteComponentProps> = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();
  const [isErrorToastOpen, setIsErrorToastOpen] = useState(false);
  const [signInMutation, { loading }] = useMutation(SIGN_IN);
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    setEmail(target.value);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    setPassword(target.value);
  };

  const signIn = () => {
    signInMutation({
      variables: {
        email,
        password,
      },
    })
      .then(({ data: { token } }) => {
        localStorage.setItem(storageToken, token);
        history.push(routes.paintings);
      })
      .catch(() => setIsErrorToastOpen(true));
  };

  const isFormValid = () => email !== '' && password !== '';

  const handleSignInClick = () => {
    signIn();
  };

  const handleToastClose = () => {
    setIsErrorToastOpen(false);
  };

  return (
    <Page>
      <SignInContainer>
        <LogoContainer>
          <img src={logo} alt="LenaLotten Logotyp" />
        </LogoContainer>
        <SignInCard>
          <Card>
            <CardContent>
              <TitleContainer>
                <Typography variant="h5" component="h1">
                  Sign In
                </Typography>
              </TitleContainer>
              <TextField
                fullWidth
                value={email}
                label="Email"
                type="email"
                onChange={handleEmailChange}
              />
              <PasswordInputContainer>
                <TextField
                  fullWidth
                  value={password}
                  label="Password"
                  type="password"
                  onChange={handlePasswordChange}
                />
              </PasswordInputContainer>
              <ButtonContainer>
                {!loading ? (
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={!isFormValid() || loading}
                    onClick={handleSignInClick}
                  >
                    Sign In
                  </Button>
                ) : (
                  <CircularProgress size={36} />
                )}
              </ButtonContainer>
            </CardContent>
          </Card>
        </SignInCard>
      </SignInContainer>
      <Snackbar
        open={isErrorToastOpen}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={toastAutoHideDuration}
        onClose={handleToastClose}
      >
        <Alert variant="filled" severity="error">
          Wrong email or password provided.
        </Alert>
      </Snackbar>
    </Page>
  );
};
