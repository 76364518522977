import { gql } from '@apollo/client';

export const HOME = gql`
  query Home {
    home {
      id
      enTitle
      svTitle
      enText
      svText
      enHtmlTitle
      svHtmlTitle
      enHtmlDescription
      svHtmlDescription
    }
  }
`;
