import React, { ReactElement, useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import {
  makeStyles,
  createStyles,
  Grid,
  Theme,
  Button,
} from '@material-ui/core';

import { FormType } from '@src/constants/forms';
import {
  AdditionalPaintingImage,
  CreateAdditionalPaintingImageInput,
  CreatePaintingInput,
  Painting,
  UpdateAdditionalPaintingImageInput,
  UpdatePaintingInput,
} from '@src/types/painting';
import { urlFromPath } from '@src/utilities/url';
import { PaintingID } from '@src/types/aliases';
import { useSlug } from '@src/hooks/useSlug';
import { youtubeVideoIdSeparator } from '@src/constants/video';

import { FooterActions } from '@src/components/shared/FooterActions/FooterActions';

import * as S from './PaintingForm.S';
import { AdditionalImages } from '@src/components/AdditionalImages/AdditionalImages';
import { DELETE_ADDITIONAL_PAINTING_IMAGE } from '@src/graphql/mutations/deleteAdditionalPaintingImage';
import { useMutation } from '@apollo/client';
import { client } from '../../../apollo-client';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
  })
);

interface PaintingFormProps {
  formType: FormType;
  paintingToUpdate?: Painting;
  onCreate?: (
    input: CreatePaintingInput,
    additionalImages: CreateAdditionalPaintingImageInput[]
  ) => void;
  onUpdate?: (
    id: PaintingID,
    input: UpdatePaintingInput,
    additionalImagesToCreate: CreateAdditionalPaintingImageInput[],
    additionalImagesToUpdate: UpdateAdditionalPaintingImageInput[]
  ) => void;
  onDelete?: (id: PaintingID) => void;
}

export const PaintingForm = ({
  formType,
  paintingToUpdate,
  onCreate,
  onUpdate,
  onDelete,
}: PaintingFormProps): ReactElement => {
  const classes = useStyles();
  const [slug, setSlug] = useSlug();
  const [title, setTitle] = useState('');
  const [enDescription, setEnDescription] = useState('');
  const [svDescription, setSvDescription] = useState('');
  const [youtubeVideoIds, setYoutubeVideoIds] = useState('');
  const [sold, setSold] = useState(false);
  const [published, setPublished] = useState(true);
  const [width, setWidth] = useState('');
  const [height, setHeight] = useState('');
  const [imagePath, setImagePath] = useState('');
  const [itemOrder, setItemOrder] = useState('');
  const [fileToUpload, setFileToUpload] = useState<File | null>(null);
  const [additionalImages, setAdditionalImages] = useState<
    (AdditionalPaintingImage | CreateAdditionalPaintingImageInput)[]
  >([]);

  const [deleteAdditionalPaintingImageMutation] = useMutation(
    DELETE_ADDITIONAL_PAINTING_IMAGE
  );

  const handleSoldChange = (
    _: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => setSold(checked);

  const handlePublishedChange = (
    _: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => setPublished(checked);

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    setTitle(target.value);
  };

  const handleSlugChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    setSlug(target.value);
  };

  const handleEnDescriptionChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const target = e.target;
    setEnDescription(target.value);
  };

  const handleSvDescriptionChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const target = e.target;
    setSvDescription(target.value);
  };

  const handleYoutubeVideoIdsChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const target = e.target;
    setYoutubeVideoIds(target.value);
  };

  const handleWidthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    setWidth(target.value);
  };

  const handleHeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    setHeight(target.value);
  };

  const handleOrderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    setItemOrder(target.value);
  };

  const renderImage = (): ReactElement | null => {
    if (imagePath === '') {
      return null;
    }
    return <img width="200" src={urlFromPath(imagePath)} alt="painting" />;
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const files = e.target.files;
    const file = files?.[0] || null;
    setFileToUpload(file);
  };

  const handleCreate = () => {
    if (title.trim() && slug.trim() && width && height && fileToUpload) {
      const input: CreatePaintingInput = {
        slug,
        title: title.trim(),
        enDescription,
        svDescription,
        sold,
        width: Number(width),
        height: Number(height),
        published,
        fileUpload: fileToUpload,
        youtubeVideoIds: youtubeVideoIds.trim(),
      };
      onCreate &&
        onCreate(
          input,
          additionalImages.filter(
            (additionalImage) =>
              'fileUpload' in additionalImage && !!additionalImage.fileUpload
          ) as CreateAdditionalPaintingImageInput[]
        );
    }
  };

  const handleUpdate = () => {
    const additionalImagesToUpdate = additionalImages
      .filter((additionalImage) => 'id' in additionalImage)
      .map(
        (ai): UpdateAdditionalPaintingImageInput => {
          const additionalImage = ai as AdditionalPaintingImage;
          return {
            id: additionalImage.id,
            paintingID: additionalImage.paintingID,
            enDescription: additionalImage.enDescription,
            svDescription: additionalImage.svDescription,
            itemOrder: additionalImage.itemOrder,
          };
        }
      );

    const additionalImagesToCreate = additionalImages.filter(
      (additionalImage) =>
        'fileUpload' in additionalImage && !!additionalImage.fileUpload
    ) as CreateAdditionalPaintingImageInput[];
    if (
      paintingToUpdate &&
      title.trim() &&
      slug.trim() &&
      width &&
      height &&
      itemOrder
    ) {
      const input: UpdatePaintingInput = {
        slug,
        title: title.trim(),
        enDescription,
        svDescription,
        sold,
        width: Number(width),
        height: Number(height),
        published,
        itemOrder: Number(itemOrder),
        fileUpload: fileToUpload,
        youtubeVideoIds: youtubeVideoIds.trim(),
      };
      onUpdate &&
        onUpdate(
          paintingToUpdate.id,
          input,
          additionalImagesToCreate,
          additionalImagesToUpdate
        );
    }
  };

  const handleDelete = () => {
    const shouldDelete = window.confirm('Are you sure?');
    if (shouldDelete && onDelete && paintingToUpdate) {
      onDelete(paintingToUpdate.id);
    }
  };

  const deriveStateFromPaintingToUpdate = () => {
    if (paintingToUpdate) {
      setSlug(paintingToUpdate.slug);
      setTitle(paintingToUpdate.title);
      setSold(paintingToUpdate.sold);
      setPublished(paintingToUpdate.published);
      setWidth(String(paintingToUpdate.width));
      setHeight(String(paintingToUpdate.height));
      setImagePath(paintingToUpdate.imagePath);
      setItemOrder(String(paintingToUpdate.itemOrder));
      setEnDescription(paintingToUpdate.enDescription);
      setSvDescription(paintingToUpdate.svDescription);
      setYoutubeVideoIds(paintingToUpdate.youtubeVideoIds);
      setAdditionalImages(paintingToUpdate.additionalImages);
    }
  };

  useEffect(deriveStateFromPaintingToUpdate, [paintingToUpdate, setSlug]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item md={12} className={classes.root}>
          <TextField
            data-testid="slug"
            value={slug}
            fullWidth
            label="Slug"
            rows={10}
            variant="outlined"
            onChange={handleSlugChange}
          />
        </Grid>
        <Grid item md={12} className={classes.root}>
          <TextField
            data-testid="title"
            value={title}
            fullWidth
            label="Title"
            rows={10}
            variant="outlined"
            onChange={handleTitleChange}
          />
        </Grid>
        <Grid item md={6} className={classes.root}>
          <TextField
            data-testid="en-description"
            value={enDescription}
            fullWidth
            multiline
            label="Description 🇬🇧"
            rows={10}
            variant="outlined"
            onChange={handleEnDescriptionChange}
          />
        </Grid>
        <Grid item md={6} className={classes.root}>
          <TextField
            data-testid="sv-description"
            value={svDescription}
            fullWidth
            multiline
            label="Description 🇸🇪"
            rows={10}
            variant="outlined"
            onChange={handleSvDescriptionChange}
          />
        </Grid>
        <Grid item md={3} className={classes.root}>
          <TextField
            id="outlined-number"
            value={width}
            fullWidth
            label="Width in cm"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{ inputProps: { min: 0 } }}
            variant="outlined"
            onChange={handleWidthChange}
          />
        </Grid>
        <Grid item md={3} className={classes.root}>
          <TextField
            id="outlined-number"
            value={height}
            fullWidth
            label="Height in cm"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{ inputProps: { min: 0 } }}
            variant="outlined"
            onChange={handleHeightChange}
          />
        </Grid>
        <Grid item md={2} className={classes.root}>
          <FormControl component="fieldset">
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={sold}
                    onChange={handleSoldChange}
                  />
                }
                label="Sold"
                labelPlacement="end"
              />
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item md={2} className={classes.root}>
          <FormControl component="fieldset">
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={published}
                    onChange={handlePublishedChange}
                  />
                }
                label="Published"
                labelPlacement="end"
              />
            </FormGroup>
          </FormControl>
        </Grid>
        {formType === FormType.Update && (
          <Grid item md={12} className={classes.root}>
            <TextField
              id="outlined-number"
              value={itemOrder}
              fullWidth
              label="Order"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{ inputProps: { min: 0 } }}
              variant="outlined"
              onChange={handleOrderChange}
            />
          </Grid>
        )}
        <Grid item md={12} className={classes.root}>
          <S.ImageUploadContainer>
            <S.MainPaintingImageHeading className="mt0">
              Main painting image:
            </S.MainPaintingImageHeading>
            {renderImage()}
            <input type="file" onChange={handleFileChange} />
          </S.ImageUploadContainer>
        </Grid>
        <Grid item md={12} className={classes.root}>
          <h3 className="mt0">Videos:</h3>
          <TextField
            data-testid="youtube-video-ids"
            value={youtubeVideoIds}
            fullWidth
            label="Youtube Video ID's, separate ID's with a space"
            variant="outlined"
            onChange={handleYoutubeVideoIdsChange}
          />
          {youtubeVideoIds.split(youtubeVideoIdSeparator).map((videoID) => {
            if (videoID.trim() === '') {
              return null;
            }
            return (
              <S.YoutubeVideoContainer>
                <iframe
                  width="300"
                  height="150"
                  src={`https://www.youtube-nocookie.com/embed/${videoID}`}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </S.YoutubeVideoContainer>
            );
          })}
        </Grid>
        <Grid item md={12} className={classes.root}>
          <AdditionalImages
            onRemove={async (id: number) => {
              await deleteAdditionalPaintingImageMutation({
                variables: { id },
              });

              await client.reFetchObservableQueries();
            }}
            additionalImages={additionalImages}
            onChangeAll={(additionalImages) => {
              setAdditionalImages(
                additionalImages as (
                  | AdditionalPaintingImage
                  | CreateAdditionalPaintingImageInput
                )[]
              );
            }}
            onChange={(newAdditionalImage, index) => {
              const newAdditionalImages = additionalImages.map((image, i) => {
                if (i === index) {
                  return newAdditionalImage;
                }
                return image;
              });
              setAdditionalImages(
                newAdditionalImages as (
                  | AdditionalPaintingImage
                  | CreateAdditionalPaintingImageInput
                )[]
              );
            }}
          />
        </Grid>
      </Grid>
      <FooterActions>
        {formType === FormType.Create && (
          <Button
            data-testid="create-button"
            variant="contained"
            color="primary"
            onClick={handleCreate}
          >
            Create
          </Button>
        )}
        {formType === FormType.Update && (
          <>
            <Button
              data-testid="delete-button"
              variant="contained"
              color="secondary"
              onClick={handleDelete}
              style={{ marginRight: '15px' }}
            >
              Delete
            </Button>
            <Button
              data-testid="update-button"
              variant="contained"
              color="primary"
              onClick={handleUpdate}
            >
              Update
            </Button>
          </>
        )}
      </FooterActions>
    </>
  );
};
