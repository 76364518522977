import React from 'react';
import { ReactElement } from 'react';
import { generatePath } from 'react-router';
import { Link } from 'react-router-dom';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { green } from '@material-ui/core/colors';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import PublicIcon from '@material-ui/icons/Public';

import { Painting } from '@src/types/painting';
import { urlFromPath } from '@src/utilities/url';
import { Button } from '@material-ui/core';
import { PaintingID } from '@src/types/aliases';
import { routes } from '@src/constants/routes';

import * as S from './PaintingsTable.S';

interface PaintingsTableProps {
  paintings: Painting[];
  onPaintingsChange: (newPaintings: Painting[]) => void;
}

export const PaintingsTable = ({
  paintings,
  onPaintingsChange,
}: PaintingsTableProps): ReactElement => {
  const handleDragEnd = ({ source, destination }: DropResult) => {
    if (!destination) {
      return;
    }
    if (source.index === destination.index) {
      return;
    }
    let newPaintings = [...paintings];
    const draggedPaintings = newPaintings.splice(source.index, 1);
    newPaintings.splice(destination.index, 0, ...draggedPaintings);
    newPaintings = newPaintings.map(
      (painting: Painting, index): Painting => ({
        ...painting,
        itemOrder: index,
      })
    );
    onPaintingsChange(newPaintings);
  };

  const getUpdateLink = (paintingID: PaintingID): string =>
    generatePath(routes.paintingsUpdate, {
      paintingID: String(paintingID),
    });

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="1">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            <TableContainer component={Paper}>
              <Table stickyHeader aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>Title</TableCell>
                    <TableCell>Width</TableCell>
                    <TableCell>Height</TableCell>
                    <TableCell align="center">Sold</TableCell>
                    <TableCell align="center">Published</TableCell>
                    <TableCell align="center">Order</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paintings.map((painting: Painting, index: number) => (
                    <Draggable
                      key={painting.id}
                      draggableId={String(painting.id)}
                      index={index}
                    >
                      {(providedDraggable) => (
                        <TableRow
                          {...providedDraggable.draggableProps}
                          {...providedDraggable.dragHandleProps}
                          ref={providedDraggable.innerRef}
                        >
                          <TableCell align="center" padding="none">
                            <S.ImageContainer>
                              <S.Image
                                src={urlFromPath(painting.imagePath)}
                                alt="about"
                              />
                            </S.ImageContainer>
                          </TableCell>
                          <TableCell>{painting.title}</TableCell>
                          <TableCell>{painting.width} cm</TableCell>
                          <TableCell>{painting.height} cm</TableCell>
                          <TableCell align="center">
                            {painting.sold ? (
                              <FiberManualRecordIcon
                                color="secondary"
                                fontSize="small"
                              />
                            ) : null}
                          </TableCell>
                          <TableCell align="center">
                            {painting.published ? (
                              <PublicIcon
                                style={{ color: green[500] }}
                                fontSize="small"
                              />
                            ) : null}
                          </TableCell>
                          <TableCell align="center">
                            {painting.itemOrder}
                          </TableCell>
                          <TableCell align="center">
                            <Link to={getUpdateLink(painting.id)}>
                              <Button variant="contained" color="primary">
                                Update
                              </Button>
                            </Link>
                          </TableCell>
                        </TableRow>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
