import { gql } from '@apollo/client';

export const CONTACT = gql`
  query Contact {
    contact {
      id
      email
      phone
      imagePath
      svText
      enText
      enHtmlTitle
      svHtmlTitle
      enHtmlDescription
      svHtmlDescription
    }
  }
`;
