import React from 'react';
import { ReactElement } from 'react';
import { generatePath } from 'react-router';
import { Link } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { green } from '@material-ui/core/colors';
import PublicIcon from '@material-ui/icons/Public';

import { Exhibition } from '@src/types/exhibition';
import { urlFromPath } from '@src/utilities/url';
import { Button } from '@material-ui/core';
import { URLSlug } from '@src/types/aliases';
import { routes } from '@src/constants/routes';

import * as S from './ExhibitionsTable.S';
import { formatDate } from '@src/utilities/date';

interface ExhibitionsTableProps {
  exhibitions: Exhibition[];
}

export const ExhibitionsTable = ({
  exhibitions,
}: ExhibitionsTableProps): ReactElement => {
  const getUpdateLink = (slug: URLSlug): string =>
    generatePath(routes.exhibitionsUpdate, {
      slug,
    });

  return (
    <div>
      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>🇬🇧 Title</TableCell>
              <TableCell>🇸🇪 Title</TableCell>
              <TableCell>Start</TableCell>
              <TableCell>End</TableCell>
              <TableCell align="center">Published</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {exhibitions.map((exhibition: Exhibition) => (
              <TableRow key={exhibition.slug}>
                <TableCell align="center" padding="none">
                  <S.ImageContainer>
                    <S.Image
                      src={urlFromPath(exhibition.imagePath)}
                      alt="cover"
                    />
                  </S.ImageContainer>
                </TableCell>
                <TableCell>{exhibition.enTitle}</TableCell>
                <TableCell>{exhibition.svTitle}</TableCell>
                <TableCell>{formatDate(exhibition.startAt)}</TableCell>
                <TableCell>{formatDate(exhibition.endAt)}</TableCell>

                <TableCell align="center">
                  {exhibition.published ? (
                    <PublicIcon
                      style={{ color: green[500] }}
                      fontSize="small"
                    />
                  ) : null}
                </TableCell>
                <TableCell align="center">
                  <Link to={getUpdateLink(exhibition.slug)}>
                    <Button variant="contained" color="primary">
                      Update
                    </Button>
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
