import styled from 'styled-components';
import { IconButton } from '@material-ui/core';

export const Container = styled.div`
  margin-top: 20px;
  padding: 20px;
  border-radius: 7px;
  border: 1px solid #dfdfdf;
  position: relative;

  > div {
    margin-top: 10px;
  }
`;

export const Image = styled.img`
  max-width: 120px;
  max-height: 120px;
`;

export const DeleteButton = styled(IconButton)`
  position: absolute !important;
  top: 2px;
  right: 5px;
`;
