import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { RouteComponentProps } from 'react-router-dom';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

import { toastAutoHideDuration } from '@src/constants/toasts';
import { ABOUT } from '@src/graphql/queries/about';
import { UPDATE_ABOUT } from '@src/graphql/mutations/updateAbout';
import { AboutQuery, AboutInput } from '@src/types/about';
import { urlFromPath } from '@src/utilities/url';

import { DashboardShell } from '@src/components/shared/DashboardShell/DashboardShell';
import { DashboardHeading } from '@src/components/shared/DashboardHeading/DashboardHeading';
import { FooterActions } from '@src/components/shared/FooterActions/FooterActions';
import { Content } from '@src/components/shared/Content/Content';

import * as S from './AboutPage.S';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
  })
);

export const AboutPage: React.FC<RouteComponentProps> = () => {
  const classes = useStyles();
  const [aboutTextEn, setAboutTextEn] = useState('');
  const [aboutTextSv, setAboutTextSv] = useState('');
  const [enHtmlTitle, setEnHtmlTitle] = useState('');
  const [svHtmlTitle, setSvHtmlTitle] = useState('');
  const [enHtmlDescription, setEnHtmlDescription] = useState('');
  const [svHtmlDescription, setSvHtmlDescription] = useState('');
  const [imagePath, setImagePath] = useState('');
  const [fileToUpload, setFileToUpload] = useState<File | null>(null);
  const [isSavedToastOpen, setIsSavedToastOpen] = useState(false);
  const { data } = useQuery<AboutQuery>(ABOUT);
  const [updateAboutMutation] = useMutation(UPDATE_ABOUT);

  const setFetchedAboutData = () => {
    if (data?.about) {
      const about = data.about;
      setAboutTextEn(about.enText);
      setAboutTextSv(about.svText);
      setImagePath(about.imagePath);
      setEnHtmlTitle(about.enHtmlTitle);
      setSvHtmlTitle(about.svHtmlTitle);
      setEnHtmlDescription(about.enHtmlDescription);
      setSvHtmlDescription(about.svHtmlDescription);
    }
  };
  useEffect(setFetchedAboutData, [data]);

  const handleEnHtmlTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    setEnHtmlTitle(target.value);
  };

  const handleSvHtmlTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    setSvHtmlTitle(target.value);
  };

  const handleEnHtmlDescriptionChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const target = e.target;
    setEnHtmlDescription(target.value);
  };

  const handleSvHtmlDescriptionChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const target = e.target;
    setSvHtmlDescription(target.value);
  };

  const handleAboutTextEnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    setAboutTextEn(target.value);
  };

  const handleAboutTextSvChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    setAboutTextSv(target.value);
  };

  const handleToastClose = () => {
    setIsSavedToastOpen(false);
  };

  const handleSave = () => {
    const input: AboutInput = {
      enText: aboutTextEn,
      svText: aboutTextSv,
      fileUpload: fileToUpload,
      enHtmlTitle,
      svHtmlTitle,
      enHtmlDescription,
      svHtmlDescription,
    };
    updateAboutMutation({
      variables: { input },
    }).then(() => {
      setIsSavedToastOpen(true);
    });
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    const file = files?.[0] || null;
    setFileToUpload(file);
  };

  const renderImage = () => {
    if (imagePath === '') {
      return null;
    }
    return <img width="200" src={urlFromPath(imagePath)} alt="about" />;
  };

  return (
    <DashboardShell>
      <DashboardHeading>About</DashboardHeading>
      <Content>
        <Grid container spacing={3}>
          <Grid item md={6} className={classes.root}>
            <TextField
              data-testid="about-en-html-title-field"
              value={enHtmlTitle}
              fullWidth
              label="HTML Title 🇬🇧"
              rows={10}
              variant="outlined"
              onChange={handleEnHtmlTitleChange}
            />
          </Grid>
          <Grid item md={6} className={classes.root}>
            <TextField
              data-testid="about-sv-html-title-field"
              value={svHtmlTitle}
              fullWidth
              label="HTML Title 🇸🇪"
              rows={10}
              variant="outlined"
              onChange={handleSvHtmlTitleChange}
            />
          </Grid>
          <Grid item md={6} className={classes.root}>
            <TextField
              data-testid="about-en-html-description-field"
              value={enHtmlDescription}
              fullWidth
              label="HTML Description 🇬🇧"
              multiline
              rows={10}
              variant="outlined"
              onChange={handleEnHtmlDescriptionChange}
            />
          </Grid>
          <Grid item md={6} className={classes.root}>
            <TextField
              data-testid="about-sv-html-description-field"
              value={svHtmlDescription}
              fullWidth
              label="HTML Description 🇸🇪"
              multiline
              rows={10}
              variant="outlined"
              onChange={handleSvHtmlDescriptionChange}
            />
          </Grid>
          <Grid item md={6} className={classes.root}>
            <TextField
              data-testid="about-text-en-field"
              value={aboutTextEn}
              fullWidth
              label="Body text 🇬🇧"
              multiline
              rows={10}
              variant="outlined"
              onChange={handleAboutTextEnChange}
            />
          </Grid>
          <Grid item md={6} className={classes.root}>
            <TextField
              data-testid="about-text-sv-field"
              value={aboutTextSv}
              fullWidth
              label="Body text 🇸🇪"
              multiline
              rows={10}
              variant="outlined"
              onChange={handleAboutTextSvChange}
            />
          </Grid>
        </Grid>
        <S.ImageUploadContainer>
          {renderImage()}
          <input type="file" onChange={handleFileChange} />
        </S.ImageUploadContainer>
        <FooterActions>
          <Button
            data-testid="save-button"
            variant="contained"
            color="primary"
            onClick={handleSave}
          >
            Save
          </Button>
        </FooterActions>
        <Snackbar
          open={isSavedToastOpen}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={toastAutoHideDuration}
          onClose={handleToastClose}
        >
          <Alert variant="filled" severity="success">
            Saved successfully &nbsp;❤️
          </Alert>
        </Snackbar>
      </Content>
    </DashboardShell>
  );
};
