import { gql } from '@apollo/client';

export const CREATE_PAINTING = gql`
  mutation CreatePainting(
    $input: CreatePaintingInput!
    $additionalImages: [CreateAdditionalPaintingImageInput!]!
  ) {
    createdPainting: createPainting(
      input: $input
      additionalImages: $additionalImages
    ) {
      id
      slug
      imagePath
      title
      enDescription
      svDescription
      itemOrder
      sold
      width
      height
      published
      youtubeVideoIds
    }
  }
`;
