import { gql } from '@apollo/client';

export const UPDATE_PAINTING = gql`
  mutation UpdatePainting(
    $id: Int!
    $input: UpdatePaintingInput!
    $additionalImagesToCreate: [CreateAdditionalPaintingImageInput!]!
    $additionalImagesToUpdate: [UpdateAdditionalPaintingImageInput!]!
  ) {
    updatedPainting: updatePainting(
      id: $id
      input: $input
      additionalImagesToCreate: $additionalImagesToCreate
      additionalImagesToUpdate: $additionalImagesToUpdate
    ) {
      id
      slug
      imagePath
      title
      enDescription
      svDescription
      itemOrder
      sold
      width
      height
      published
      youtubeVideoIds
    }
  }
`;
