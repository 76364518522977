import React, { useState, useEffect, ReactElement } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { RouteComponentProps } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import InputAdornment from '@material-ui/core/InputAdornment';
import PhoneIphone from '@material-ui/icons/PhoneIphone';
import Email from '@material-ui/icons/Email';
import Alert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { UPDATE_CONTACT } from '@src/graphql/mutations/updateContact';
import { toastAutoHideDuration } from '@src/constants/toasts';
import { CONTACT } from '@src/graphql/queries/contact';
import { ContactQuery, ContactInput } from '@src/types/contact';
import { urlFromPath } from '@src/utilities/url';

import { FooterActions } from '@src/components/shared/FooterActions/FooterActions';
import { Content } from '@src/components/shared/Content/Content';
import { DashboardShell } from '@src/components/shared/DashboardShell/DashboardShell';
import { DashboardHeading } from '@src/components/shared/DashboardHeading/DashboardHeading';

import * as S from './ContactPage.S';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
  })
);

export const ContactPage: React.FC<RouteComponentProps> = () => {
  const classes = useStyles();
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [isSavedToastOpen, setIsSavedToastOpen] = useState(false);
  const { data } = useQuery<ContactQuery>(CONTACT);
  const [contactTextEn, setContactTextEn] = useState('');
  const [contactTextSv, setContactTextSv] = useState('');
  const [enHtmlTitle, setEnHtmlTitle] = useState('');
  const [svHtmlTitle, setSvHtmlTitle] = useState('');
  const [enHtmlDescription, setEnHtmlDescription] = useState('');
  const [svHtmlDescription, setSvHtmlDescription] = useState('');
  const [imagePath, setImagePath] = useState('');
  const [fileToUpload, setFileToUpload] = useState<File | null>(null);
  const [updateContactMutation] = useMutation(UPDATE_CONTACT);

  useEffect(() => {
    if (data?.contact) {
      const { contact } = data;
      setEmail(contact.email);
      setPhone(contact.phone);
      setContactTextEn(contact.enText);
      setContactTextSv(contact.svText);
      setEnHtmlTitle(contact.enHtmlTitle);
      setSvHtmlTitle(contact.svHtmlTitle);
      setEnHtmlDescription(contact.enHtmlDescription);
      setSvHtmlDescription(contact.svHtmlDescription);
      setImagePath(contact.imagePath);
    }
  }, [data]);

  const handleEnHtmlTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    setEnHtmlTitle(target.value);
  };

  const handleSvHtmlTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    setSvHtmlTitle(target.value);
  };

  const handleEnHtmlDescriptionChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const target = e.target;
    setEnHtmlDescription(target.value);
  };

  const handleSvHtmlDescriptionChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const target = e.target;
    setSvHtmlDescription(target.value);
  };

  const handleContactTextEnChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const target = e.target;
    setContactTextEn(target.value);
  };

  const handleContactTextSvChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const target = e.target;
    setContactTextSv(target.value);
  };

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    setPhone(target.value);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    setEmail(target.value);
  };

  const handleToastClose = () => {
    setIsSavedToastOpen(false);
  };

  const handleSave = async () => {
    const input: ContactInput = {
      email,
      phone,
      enText: contactTextEn,
      svText: contactTextSv,
      enHtmlTitle,
      svHtmlTitle,
      enHtmlDescription,
      svHtmlDescription,
      fileUpload: fileToUpload,
    };
    updateContactMutation({
      variables: { input },
    }).then(() => {
      setIsSavedToastOpen(true);
    });
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const files = e.target.files;
    const file = files?.[0] || null;
    setFileToUpload(file);
  };

  const renderImage = (): ReactElement | null => {
    if (imagePath === '') {
      return null;
    }
    return <img width="200" src={urlFromPath(imagePath)} alt="contact" />;
  };

  return (
    <DashboardShell>
      <DashboardHeading>Contact</DashboardHeading>
      <Content>
        <S.TextsContainer>
          <Grid container spacing={3}>
            <Grid item md={6} className={classes.root}>
              <TextField
                data-testid="contact-en-html-title-field"
                value={enHtmlTitle}
                fullWidth
                label="HTML Title 🇬🇧"
                rows={10}
                variant="outlined"
                onChange={handleEnHtmlTitleChange}
              />
            </Grid>
            <Grid item md={6} className={classes.root}>
              <TextField
                data-testid="contact-sv-html-title-field"
                value={svHtmlTitle}
                fullWidth
                label="HTML Title 🇸🇪"
                rows={10}
                variant="outlined"
                onChange={handleSvHtmlTitleChange}
              />
            </Grid>
            <Grid item md={6} className={classes.root}>
              <TextField
                data-testid="contact-en-html-description-field"
                value={enHtmlDescription}
                fullWidth
                label="HTML Description 🇬🇧"
                multiline
                rows={10}
                variant="outlined"
                onChange={handleEnHtmlDescriptionChange}
              />
            </Grid>
            <Grid item md={6} className={classes.root}>
              <TextField
                data-testid="contact-sv-html-description-field"
                value={svHtmlDescription}
                fullWidth
                label="HTML Description 🇸🇪"
                multiline
                rows={10}
                variant="outlined"
                onChange={handleSvHtmlDescriptionChange}
              />
            </Grid>
            <Grid item md={6} className={classes.root}>
              <TextField
                data-testid="contact-text-en-field"
                value={contactTextEn}
                fullWidth
                label="Body text 🇬🇧"
                multiline
                rows={10}
                variant="outlined"
                onChange={handleContactTextEnChange}
              />
            </Grid>
            <Grid item md={6} className={classes.root}>
              <TextField
                data-testid="contact-text-sv-field"
                value={contactTextSv}
                fullWidth
                label="Body text 🇸🇪"
                multiline
                rows={10}
                variant="outlined"
                onChange={handleContactTextSvChange}
              />
            </Grid>
          </Grid>
        </S.TextsContainer>
        <Grid container spacing={4}>
          <Grid item>
            <TextField
              value={phone}
              label="Phone"
              type="tel"
              variant="outlined"
              onChange={handlePhoneNumberChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PhoneIphone />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              value={email}
              label="Email"
              type="email"
              variant="outlined"
              onChange={handleEmailChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Email />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <S.ImageUploadContainer>
          {renderImage()}
          <input type="file" onChange={handleFileChange} />
        </S.ImageUploadContainer>
        <FooterActions>
          <Button
            data-testid="save-button"
            variant="contained"
            color="primary"
            onClick={handleSave}
          >
            Save
          </Button>
        </FooterActions>
        <Snackbar
          open={isSavedToastOpen}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={toastAutoHideDuration}
          onClose={handleToastClose}
        >
          <Alert variant="filled" severity="success">
            Saved successfully &nbsp;❤️
          </Alert>
        </Snackbar>
      </Content>
    </DashboardShell>
  );
};
