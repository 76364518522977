import { gql } from '@apollo/client';

export const UPDATE_HOME = gql`
  mutation UpdateHome($input: UpdateHomeInput!) {
    updatedHome: updateHome(input: $input) {
      id
      enTitle
      svTitle
      enText
      svText
      enHtmlTitle
      svHtmlTitle
      enHtmlDescription
      svHtmlDescription
    }
  }
`;
