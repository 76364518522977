import React, { ReactElement, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { Button, Typography } from '@material-ui/core';

import { EXHIBITIONS } from '@src/graphql/queries/exhibitions';
import { routes } from '@src/constants/routes';
import { Exhibition, ExhibitionsQuery } from '@src/types/exhibition';

import { DashboardShell } from '@src/components/shared/DashboardShell/DashboardShell';
import { DashboardHeading } from '@src/components/shared/DashboardHeading/DashboardHeading';
import { ExhibitionsTable } from '../../Exhibitions/ExhibitionsTable/ExhibitionsTable';
import { Content } from '@src/components/shared/Content/Content';
import { FadeInMessage } from '@src/components/shared/styles/FadeInMessage.S';

export const ExhibitionsPage = (): ReactElement | null => {
  const { data, loading } = useQuery<ExhibitionsQuery>(EXHIBITIONS);
  const [exhibitions, setExhibitions] = useState<Exhibition[]>([]);

  useEffect(() => {
    if (data?.exhibitions) {
      setExhibitions(data.exhibitions);
    }
  }, [data]);

  const hasExhibitions = (): boolean => exhibitions.length > 0;

  const renderHeaderActions = (): ReactElement => (
    <>
      <Link to={routes.exhibitionsCreate}>
        <Button variant="contained" color="primary">
          Create an Exhibition
        </Button>
      </Link>
    </>
  );

  if (loading) {
    return null;
  }

  return (
    <DashboardShell>
      <DashboardHeading actions={renderHeaderActions()}>
        Exhibitions
      </DashboardHeading>
      <Content>
        {hasExhibitions() ? (
          <ExhibitionsTable exhibitions={exhibitions} />
        ) : (
          <FadeInMessage>
            <Typography variant="body2" component="p">
              You don't have any exhibitions, add one by clicking on "Create an
              Exhibition"! 💛🧡❤️
            </Typography>
          </FadeInMessage>
        )}
      </Content>
    </DashboardShell>
  );
};
