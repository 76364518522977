import React, { ReactElement } from 'react';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import { FormType } from '@src/constants/forms';
import {
  CreateAdditionalPaintingImageInput,
  CreatePaintingInput,
} from '@src/types/painting';
import { CREATE_PAINTING } from '@src/graphql/mutations/createPainting';
import { PAINTINGS } from '@src/graphql/queries/paintings';
import { routes } from '@src/constants/routes';

import { DashboardShell } from '@src/components/shared/DashboardShell/DashboardShell';
import { DashboardHeading } from '@src/components/shared/DashboardHeading/DashboardHeading';
import { PaintingForm } from '@src/components/Paintings/PaintingForm/PaintingForm';
import { Content } from '@src/components/shared/Content/Content';

export const PaintingCreatePage = (): ReactElement => {
  const history = useHistory();
  const [createPaintingMutation] = useMutation(CREATE_PAINTING, {
    refetchQueries: [{ query: PAINTINGS }, 'Paintings'],
  });
  const handleCreate = (
    input: CreatePaintingInput,
    additionalImages: CreateAdditionalPaintingImageInput[]
  ) => {
    createPaintingMutation({
      variables: { input, additionalImages },
    }).then(() => {
      history.push(routes.paintings);
    });
  };

  return (
    <DashboardShell>
      <DashboardHeading>Create Painting</DashboardHeading>
      <Content>
        <PaintingForm formType={FormType.Create} onCreate={handleCreate} />
      </Content>
    </DashboardShell>
  );
};
