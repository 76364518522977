export const formatDate = (dateString: string) => {
  if (!dateString) {
    return '';
  }
  const m = new Date(dateString);
  return (
    m.getUTCFullYear() +
    '-' +
    ('0' + (m.getMonth() + 1)).slice(-2) +
    '-' +
    ('0' + m.getDate()).slice(-2) +
    ' ' +
    ('0' + m.getHours()).slice(-2) +
    ':' +
    ('0' + m.getMinutes()).slice(-2)
  );
};
