import React, { ReactElement } from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import * as S from './DashboardHeading.S';

const DividerContainer = styled.div`
  margin-top: 8px;
`;

interface DashboardHeadingProps {
  children: string;
  actions?: ReactElement | ReactElement[];
}

export const DashboardHeading = ({
  children,
  actions,
}: DashboardHeadingProps) => {
  return (
    <>
      <S.HeadingAndActionsContainer>
        <Typography variant="h6" component="h1">
          {children}
        </Typography>
        {actions && <S.ActionsContainer>{actions}</S.ActionsContainer>}
      </S.HeadingAndActionsContainer>
      <DividerContainer>
        <Divider />
      </DividerContainer>
    </>
  );
};
