import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: 30px 0 15px;
`;

export const Content: React.FC = ({ children }) => (
  <Container>{children}</Container>
);
