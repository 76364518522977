import { gql } from '@apollo/client';

export const PAINTING = gql`
  query Painting($id: Int!) {
    painting(id: $id) {
      id
      slug
      imagePath
      title
      enDescription
      svDescription
      itemOrder
      sold
      width
      height
      published
      youtubeVideoIds
      additionalImages {
        id
        paintingID
        imagePath
        enDescription
        svDescription
        itemOrder
      }
    }
  }
`;
