import React, { useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';

import { routes } from './constants/routes';
import { isAuthenticated } from './utilities/auth';

import { SignInPage } from './components/pages/SignInPage/SignInPage';
import { PaintingsPage } from './components/pages/PaintingsPage/PaintingsPage';
import { PaintingCreatePage } from './components/pages/PaintingCreatePage/PaintingCreatePage';
import { PaintingUpdatePage } from './components/pages/PaintingUpdatePage/PaintingUpdatePage';
import { ExhibitionsPage } from './components/pages/ExhibitionsPage/ExhibitionsPage';
import { ExhibitionCreatePage } from './components/pages/ExhibitionCreatePage/ExhibitionCreatePage';
import { ExhibitionUpdatePage } from './components/pages/ExhibitionUpdatePage/ExhibitionUpdatePage';
import { ContactPage } from './components/pages/ContactPage/ContactPage';
import { AboutPage } from './components/pages/AboutPage/AboutPage';
import { HomePage } from './components/pages/HomePage/HomePage';

export const Routes = () => {
  const history = useHistory();
  const path = history.location.pathname;
  const authenticated = isAuthenticated();

  const isNotAuthenticatedViewingProtectedRoute =
    !authenticated && path !== routes.root;

  const isAuthenticatedViewingSignIn = authenticated && path === routes.root;

  const willRedirect =
    isNotAuthenticatedViewingProtectedRoute || isAuthenticatedViewingSignIn;

  const guardRoutes = () => {
    if (isNotAuthenticatedViewingProtectedRoute) {
      history.push(routes.root);
    }
    if (isAuthenticatedViewingSignIn) {
      history.push(routes.paintings);
    }
  };

  useEffect(guardRoutes, [
    isNotAuthenticatedViewingProtectedRoute,
    isAuthenticatedViewingSignIn,
    history,
  ]);

  if (willRedirect) {
    return null;
  }

  return (
    <>
      <Route exact path={routes.root} component={SignInPage} />
      <Route exact path={routes.paintings} component={PaintingsPage} />
      <Route
        exact
        path={routes.paintingsCreate}
        component={PaintingCreatePage}
      />
      <Route
        exact
        path={routes.paintingsUpdate}
        component={PaintingUpdatePage}
      />
      <Route exact path={routes.exhibitions} component={ExhibitionsPage} />
      <Route
        exact
        path={routes.exhibitionsCreate}
        component={ExhibitionCreatePage}
      />
      <Route
        exact
        path={routes.exhibitionsUpdate}
        component={ExhibitionUpdatePage}
      />
      <Route exact path={routes.contact} component={ContactPage} />
      <Route exact path={routes.about} component={AboutPage} />
      <Route exact path={routes.home} component={HomePage} />
    </>
  );
};
