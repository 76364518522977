import React, { ReactElement, useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import {
  makeStyles,
  createStyles,
  Grid,
  Theme,
  Button,
  Input,
  InputLabel,
} from '@material-ui/core';

import { FormType } from '@src/constants/forms';
import {
  AdditionalExhibitionImage,
  CreateAdditionalExhibitionImageInput,
  CreateExhibitionInput,
  Exhibition,
  UpdateAdditionalExhibitionImageInput,
  UpdateExhibitionInput,
} from '@src/types/exhibition';
import { urlFromPath } from '@src/utilities/url';
import { URLSlug } from '@src/types/aliases';
import { useSlug } from '@src/hooks/useSlug';
import { inputSetter } from '@src/utilities/inputSetter';
import { formatDate } from '@src/utilities/date';
import { youtubeVideoIdSeparator } from '@src/constants/video';
import { DELETE_ADDITIONAL_EXHIBITION_IMAGE } from '@src/graphql/mutations/deleteAdditionalExhibitionImage';
import { useMutation } from '@apollo/client';
import { client } from '../../../apollo-client';

import { FooterActions } from '@src/components/shared/FooterActions/FooterActions';

import * as S from './ExhibitionForm.S';
import { AdditionalImages } from '@src/components/AdditionalImages/AdditionalImages';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
  })
);

interface ExhibitionFormProps {
  formType: FormType;
  exhibitionToUpdate?: Exhibition;
  onCreate?: (
    input: CreateExhibitionInput,
    additionalImages: CreateAdditionalExhibitionImageInput[]
  ) => void;
  onUpdate?: (
    slug: URLSlug,
    input: UpdateExhibitionInput,
    additionalImagesToCreate: CreateAdditionalExhibitionImageInput[],
    additionalImagesToUpdate: UpdateAdditionalExhibitionImageInput[]
  ) => void;
  onDelete?: (slug: URLSlug) => void;
}

export const ExhibitionForm = ({
  formType,
  exhibitionToUpdate,
  onCreate,
  onUpdate,
  onDelete,
}: ExhibitionFormProps): ReactElement => {
  const classes = useStyles();
  const [slug, setSlug] = useSlug();
  const [published, setPublished] = useState(true);
  const [imagePath, setImagePath] = useState('');
  const [fileToUpload, setFileToUpload] = useState<File | null>(null);
  const [svTitle, setSvTitle] = useState('');
  const [enTitle, setEnTitle] = useState('');
  const [svBody, setSvBody] = useState('');
  const [enBody, setEnBody] = useState('');
  const [svTimeDate, setSvTimeDate] = useState('');
  const [enTimeDate, setEnTimeDate] = useState('');
  const [locationAnchorText, setLocationAnchorText] = useState('');
  const [locationAnchorLink, setLocationAnchorLink] = useState('');
  const [
    exhibitionWebsiteAnchorText,
    setExhibitionWebsiteAnchorText,
  ] = useState('');
  const [
    exhibitionWebsiteAnchorLink,
    setExhibitionWebsiteAnchorLink,
  ] = useState('');
  const [startAt, setStartAt] = useState('');
  const [endAt, setEndAt] = useState('');
  const [youtubeVideoIds, setYoutubeVideoIds] = useState('');
  const [additionalImages, setAdditionalImages] = useState<
    (AdditionalExhibitionImage | CreateAdditionalExhibitionImageInput)[]
  >([]);

  const [deleteAdditionalExhibitionImageMutation] = useMutation(
    DELETE_ADDITIONAL_EXHIBITION_IMAGE
  );

  const handlePublishedChange = (
    _: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => setPublished(checked);

  const renderImage = (): ReactElement | null => {
    if (imagePath === '') {
      return null;
    }
    return <img width="200" src={urlFromPath(imagePath)} alt="exhibition" />;
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const files = e.target.files;
    const file = files?.[0] || null;
    setFileToUpload(file);
  };

  const handleCreate = () => {
    if (svTitle.trim() && slug.trim() && startAt && endAt && fileToUpload) {
      const input: CreateExhibitionInput = {
        slug,
        published,
        enTitle,
        svTitle,
        enBody,
        svBody,
        enTimeDate,
        svTimeDate,
        startAt: new Date(startAt).toISOString(),
        endAt: new Date(endAt).toISOString(),
        locationAnchorLink,
        locationAnchorText,
        exhibitionWebsiteAnchorLink,
        exhibitionWebsiteAnchorText,
        coverFileUpload: fileToUpload,
        youtubeVideoIds: youtubeVideoIds.trim(),
      };
      onCreate &&
        onCreate(
          input,
          additionalImages.filter(
            (additionalImage) =>
              'fileUpload' in additionalImage && !!additionalImage.fileUpload
          ) as CreateAdditionalExhibitionImageInput[]
        );
    }
  };

  const handleUpdate = () => {
    const additionalImagesToUpdate = additionalImages
      .filter((additionalImage) => 'id' in additionalImage)
      .map(
        (ai): UpdateAdditionalExhibitionImageInput => {
          const additionalImage = ai as AdditionalExhibitionImage;
          return {
            id: additionalImage.id,
            exhibitionID: additionalImage.exhibitionID,
            enDescription: additionalImage.enDescription,
            svDescription: additionalImage.svDescription,
            itemOrder: additionalImage.itemOrder,
          };
        }
      );

    const additionalImagesToCreate = additionalImages.filter(
      (additionalImage) =>
        'fileUpload' in additionalImage && !!additionalImage.fileUpload
    ) as CreateAdditionalExhibitionImageInput[];
    if (
      exhibitionToUpdate &&
      svTitle.trim() &&
      startAt &&
      endAt &&
      slug.trim()
    ) {
      const input: UpdateExhibitionInput = {
        slug,
        published,
        enTitle,
        svTitle,
        enBody,
        svBody,
        enTimeDate,
        svTimeDate,
        startAt: new Date(startAt).toISOString(),
        endAt: new Date(endAt).toISOString(),
        locationAnchorLink,
        locationAnchorText,
        exhibitionWebsiteAnchorLink,
        exhibitionWebsiteAnchorText,
        coverFileUpload: fileToUpload,
        youtubeVideoIds: youtubeVideoIds.trim(),
      };
      onUpdate &&
        onUpdate(
          exhibitionToUpdate.slug,
          input,
          additionalImagesToCreate,
          additionalImagesToUpdate
        );
    }
  };

  const handleDelete = () => {
    const shouldDelete = window.confirm('Are you sure?');
    if (shouldDelete && onDelete && exhibitionToUpdate) {
      onDelete(exhibitionToUpdate.slug);
    }
  };

  const deriveStateFromExhibitionToUpdate = () => {
    if (exhibitionToUpdate) {
      setSlug(exhibitionToUpdate.slug);
      setPublished(exhibitionToUpdate.published);
      setImagePath(exhibitionToUpdate.imagePath);
      setSvTitle(exhibitionToUpdate.svTitle);
      setEnTitle(exhibitionToUpdate.enTitle);
      setSvBody(exhibitionToUpdate.svBody);
      setEnBody(exhibitionToUpdate.enBody);
      setSvTimeDate(exhibitionToUpdate.svTimeDate);
      setEnTimeDate(exhibitionToUpdate.enTimeDate);
      setLocationAnchorText(exhibitionToUpdate.locationAnchorText || '');
      setLocationAnchorLink(exhibitionToUpdate.locationAnchorLink || '');
      setExhibitionWebsiteAnchorLink(
        exhibitionToUpdate.exhibitionWebsiteAnchorLink || ''
      );
      setExhibitionWebsiteAnchorText(
        exhibitionToUpdate.exhibitionWebsiteAnchorText || ''
      );
      setStartAt(formatDate(exhibitionToUpdate.startAt));
      setEndAt(formatDate(exhibitionToUpdate.endAt));
      setYoutubeVideoIds(exhibitionToUpdate.youtubeVideoIds);
      setAdditionalImages(exhibitionToUpdate.additionalExhibitionImages);
    }
  };

  useEffect(deriveStateFromExhibitionToUpdate, [exhibitionToUpdate, setSlug]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item md={12} className={classes.root}>
          <TextField
            data-testid="slug"
            value={slug}
            fullWidth
            label="Slug"
            rows={10}
            variant="outlined"
            onChange={inputSetter(setSlug)}
          />
        </Grid>
        <Grid item md={6} className={classes.root}>
          <TextField
            data-testid="en-title"
            value={enTitle}
            fullWidth
            label="Title 🇬🇧"
            rows={10}
            variant="outlined"
            onChange={inputSetter(setEnTitle)}
          />
        </Grid>
        <Grid item md={6} className={classes.root}>
          <TextField
            data-testid="sv-title"
            value={svTitle}
            fullWidth
            label="Title 🇸🇪"
            rows={10}
            variant="outlined"
            onChange={inputSetter(setSvTitle)}
          />
        </Grid>
        <Grid item md={6} className={classes.root}>
          <TextField
            data-testid="en-body"
            value={enBody}
            multiline
            fullWidth
            label="Body 🇬🇧"
            rows={10}
            variant="outlined"
            onChange={inputSetter(setEnBody)}
          />
        </Grid>
        <Grid item md={6} className={classes.root}>
          <TextField
            data-testid="sv-body"
            value={svBody}
            multiline
            fullWidth
            label="Body 🇸🇪"
            rows={10}
            variant="outlined"
            onChange={inputSetter(setSvBody)}
          />
        </Grid>
        <Grid item md={6} className={classes.root}>
          <TextField
            data-testid="en-timedate"
            value={enTimeDate}
            fullWidth
            multiline
            label="Time & Date 🇬🇧"
            rows={10}
            variant="outlined"
            onChange={inputSetter(setEnTimeDate)}
          />
        </Grid>
        <Grid item md={6} className={classes.root}>
          <TextField
            data-testid="sv-timedate"
            value={svTimeDate}
            fullWidth
            multiline
            label="Time & Date 🇸🇪"
            rows={10}
            variant="outlined"
            onChange={inputSetter(setSvTimeDate)}
          />
        </Grid>
        <Grid item md={6} className={classes.root}>
          <TextField
            data-testid="location-anchor-link"
            value={locationAnchorLink}
            fullWidth
            label="Location Link"
            rows={10}
            variant="outlined"
            onChange={inputSetter(setLocationAnchorLink)}
          />
        </Grid>
        <Grid item md={6} className={classes.root}>
          <TextField
            data-testid="location-anchor-text"
            value={locationAnchorText}
            fullWidth
            label="Location Link Text"
            rows={10}
            variant="outlined"
            onChange={inputSetter(setLocationAnchorText)}
          />
        </Grid>
        <Grid item md={6} className={classes.root}>
          <TextField
            data-testid="website-anchor-link"
            value={exhibitionWebsiteAnchorLink}
            fullWidth
            label="Exhibition Website Link"
            rows={10}
            variant="outlined"
            onChange={inputSetter(setExhibitionWebsiteAnchorLink)}
          />
        </Grid>
        <Grid item md={6} className={classes.root}>
          <TextField
            data-testid="website-anchor-link-text"
            value={exhibitionWebsiteAnchorText}
            fullWidth
            label="Exhibition Website Link Text"
            rows={10}
            variant="outlined"
            onChange={inputSetter(setExhibitionWebsiteAnchorText)}
          />
        </Grid>
        <Grid item md={3} className={classes.root}>
          <InputLabel htmlFor="startAt">Start</InputLabel>
          <Input
            id="startAt"
            value={startAt}
            type="datetime-local"
            onChange={inputSetter(setStartAt)}
          />
        </Grid>
        <Grid item md={3} className={classes.root}>
          <InputLabel htmlFor="endAt">End</InputLabel>
          <Input
            id="endAt"
            value={endAt}
            type="datetime-local"
            onChange={inputSetter(setEndAt)}
          />
        </Grid>
        <Grid item md={12} className={classes.root}>
          <FormControl component="fieldset">
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={published}
                    onChange={handlePublishedChange}
                  />
                }
                label="Published"
                labelPlacement="end"
              />
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item md={12} className={classes.root}>
          <S.ImageUploadContainer>
            <S.MainPaintingImageHeading className="mt0">
              Cover image:
            </S.MainPaintingImageHeading>
            {renderImage()}
            <input type="file" onChange={handleFileChange} />
          </S.ImageUploadContainer>
        </Grid>
        <Grid item md={12} className={classes.root}>
          <h3 className="mt0">Videos:</h3>
          <TextField
            data-testid="youtube-video-ids"
            value={youtubeVideoIds}
            fullWidth
            label="Youtube Video ID's, separate ID's with a space"
            variant="outlined"
            onChange={inputSetter(setYoutubeVideoIds)}
          />
          {youtubeVideoIds.split(youtubeVideoIdSeparator).map((videoID) => {
            if (videoID.trim() === '') {
              return null;
            }
            return (
              <S.YoutubeVideoContainer key={videoID}>
                <iframe
                  width="300"
                  height="150"
                  src={`https://www.youtube-nocookie.com/embed/${videoID}`}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </S.YoutubeVideoContainer>
            );
          })}
        </Grid>
        <Grid item md={12} className={classes.root}>
          <AdditionalImages
            onRemove={async (id: number) => {
              await deleteAdditionalExhibitionImageMutation({
                variables: { id },
              });

              await client.reFetchObservableQueries();
            }}
            additionalImages={additionalImages}
            onChangeAll={(additionalImages) => {
              setAdditionalImages(
                additionalImages as (
                  | AdditionalExhibitionImage
                  | CreateAdditionalExhibitionImageInput
                )[]
              );
            }}
            onChange={(newAdditionalImage, index) => {
              const newAdditionalImages = additionalImages.map((image, i) => {
                if (i === index) {
                  return newAdditionalImage;
                }
                return image;
              });
              setAdditionalImages(
                newAdditionalImages as (
                  | AdditionalExhibitionImage
                  | CreateAdditionalExhibitionImageInput
                )[]
              );
            }}
          />
        </Grid>
      </Grid>
      <FooterActions>
        {formType === FormType.Create && (
          <Button
            data-testid="create-button"
            variant="contained"
            color="primary"
            onClick={handleCreate}
          >
            Create
          </Button>
        )}
        {formType === FormType.Update && (
          <>
            <Button
              data-testid="delete-button"
              variant="contained"
              color="secondary"
              onClick={handleDelete}
              style={{ marginRight: '15px' }}
            >
              Delete
            </Button>
            <Button
              data-testid="update-button"
              variant="contained"
              color="primary"
              onClick={handleUpdate}
            >
              Update
            </Button>
          </>
        )}
      </FooterActions>
    </>
  );
};
