import { useState, useCallback } from 'react';

export const useSlug = () => {
  const [slug, setSlug] = useState('');

  const setSlugWrapper = useCallback(
    (newSlug: string) => {
      setSlug(newSlug.toLowerCase().replace(' ', '-').replace('--', '-'));
    },
    [setSlug]
  );

  return [slug, setSlugWrapper] as const;
};
