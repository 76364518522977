import React, { ReactElement } from 'react';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import { FormType } from '@src/constants/forms';
import {
  CreateExhibitionInput,
  CreateAdditionalExhibitionImageInput,
} from '@src/types/exhibition';
import { CREATE_EXHIBITION } from '@src/graphql/mutations/createExhibition';
import { EXHIBITIONS } from '@src/graphql/queries/exhibitions';
import { routes } from '@src/constants/routes';

import { DashboardShell } from '@src/components/shared/DashboardShell/DashboardShell';
import { DashboardHeading } from '@src/components/shared/DashboardHeading/DashboardHeading';
import { ExhibitionForm } from '@src/components/Exhibitions/ExhibitionForm/ExhibitionForm';
import { Content } from '@src/components/shared/Content/Content';

export const ExhibitionCreatePage = (): ReactElement => {
  const history = useHistory();
  const [createExhibitionMutation] = useMutation(CREATE_EXHIBITION, {
    refetchQueries: [{ query: EXHIBITIONS }, 'Exhibitions'],
  });
  const handleCreate = (
    input: CreateExhibitionInput,
    additionalImages: CreateAdditionalExhibitionImageInput[]
  ) => {
    createExhibitionMutation({
      variables: { input, additionalImages },
    }).then(() => {
      history.push(routes.exhibitions);
    });
  };

  return (
    <DashboardShell>
      <DashboardHeading>Create Exhibition</DashboardHeading>
      <Content>
        <ExhibitionForm formType={FormType.Create} onCreate={handleCreate} />
      </Content>
    </DashboardShell>
  );
};
