import { gql } from '@apollo/client';

export const ABOUT = gql`
  query About {
    about {
      id
      imagePath
      enText
      svText
      enHtmlTitle
      svHtmlTitle
      enHtmlDescription
      svHtmlDescription
    }
  }
`;
