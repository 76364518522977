import { gql } from '@apollo/client';

export const UPDATE_EXHIBITION = gql`
  mutation UpdateExhibition(
    $slug: String!
    $input: UpdateExhibitionInput!
    $additionalImagesToCreate: [CreateAdditionalExhibitionImageInput!]!
    $additionalImagesToUpdate: [UpdateAdditionalExhibitionImageInput!]!
  ) {
    updatedExhibition: updateExhibition(
      slug: $slug
      input: $input
      additionalImagesToCreate: $additionalImagesToCreate
      additionalImagesToUpdate: $additionalImagesToUpdate
    ) {
      id
      slug
      imagePath
      svTitle
      enTitle
      svBody
      enBody
      svTimeDate
      enTimeDate
      published
      locationAnchorText
      locationAnchorLink
      exhibitionWebsiteAnchorText
      exhibitionWebsiteAnchorLink
      startAt
      endAt
      youtubeVideoIds
    }
  }
`;
