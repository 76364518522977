import styled from 'styled-components';

export const FadeInMessage = styled.div`
  opacity: 0;
  animation: fadeIn 0s 0.5s;
  animation-fill-mode: forwards;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
