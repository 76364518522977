import { gql } from '@apollo/client';

export const PAINTINGS = gql`
  query Paintings {
    paintings {
      id
      slug
      imagePath
      title
      enDescription
      svDescription
      itemOrder
      sold
      width
      height
      published
    }
  }
`;
