import { ApolloClient, InMemoryCache, from } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

import { API_URL } from '@src/config/api';
import { logout } from './utilities/logout';
import { storageToken } from './constants/keys';

const UNAUTHORIZED = 401;

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (networkError && 'statusCode' in networkError) {
    if (networkError.statusCode === UNAUTHORIZED) {
      logout();
    }
  }
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {});
  }
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(storageToken);
  if (!token) {
    return {
      headers: {
        ...headers,
      },
    };
  }
  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  };
});

const link = from([
  errorLink,
  authLink,
  // @ts-ignore
  createUploadLink({
    uri: `${API_URL}/query`,
  }),
]);

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link,
});
