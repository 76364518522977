import React, { forwardRef } from 'react';

import {
  AdditionalImageType,
  CreateAdditionalImageInput,
} from '@src/types/image';
import { urlFromPath } from '@src/utilities/url';

import Delete from '@material-ui/icons/Delete';

import * as S from './AdditionalImage.S';
import { TextField } from '@material-ui/core';

export const AdditionalImage = forwardRef<
  HTMLDivElement,
  {
    additionalImage: AdditionalImageType | CreateAdditionalImageInput;
    onChange: (
      additionalImage: AdditionalImageType | CreateAdditionalImageInput
    ) => void;
    onRemove: () => void;
  }
>(({ additionalImage, onChange, onRemove, ...props }, ref) => {
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const files = e.target.files;
    const file = files?.[0] || null;
    onChange({ ...additionalImage, fileUpload: file });
  };

  return (
    <S.Container {...props} ref={ref}>
      <S.DeleteButton onClick={onRemove}>
        <Delete />
      </S.DeleteButton>
      {'imagePath' in additionalImage && (
        <S.Image src={urlFromPath(additionalImage.imagePath)} />
      )}
      {'fileUpload' in additionalImage &&
        additionalImage.fileUpload === null && (
          <input type="file" onChange={handleFileChange} />
        )}
      {'fileUpload' in additionalImage &&
        additionalImage.fileUpload !== null &&
        additionalImage.fileUpload.name}
      <TextField
        value={additionalImage.enDescription}
        fullWidth
        label="Description 🇬🇧"
        type="text"
        variant="outlined"
        onChange={(e) =>
          onChange({ ...additionalImage, enDescription: e.target.value })
        }
      />
      <TextField
        value={additionalImage.svDescription}
        fullWidth
        label="Description 🇸🇪"
        type="text"
        variant="outlined"
        onChange={(e) =>
          onChange({ ...additionalImage, svDescription: e.target.value })
        }
      />
    </S.Container>
  );
});
