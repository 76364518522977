import { gql } from '@apollo/client';

export const CREATE_EXHIBITION = gql`
  mutation CreateExhibition(
    $input: CreateExhibitionInput!
    $additionalImages: [CreateAdditionalExhibitionImageInput!]!
  ) {
    createdExhibition: createExhibition(
      input: $input
      additionalImages: $additionalImages
    ) {
      id
      slug
      imagePath
      svTitle
      enTitle
      svBody
      enBody
      svTimeDate
      enTimeDate
      published
      locationAnchorText
      locationAnchorLink
      exhibitionWebsiteAnchorText
      exhibitionWebsiteAnchorLink
      startAt
      endAt
      youtubeVideoIds
    }
  }
`;
